// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-3-d-printing-js": () => import("./../../../src/pages/3d-printing.js" /* webpackChunkName: "component---src-pages-3-d-printing-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cz-3-d-tisk-js": () => import("./../../../src/pages/cz/3d-tisk.js" /* webpackChunkName: "component---src-pages-cz-3-d-tisk-js" */),
  "component---src-pages-cz-index-js": () => import("./../../../src/pages/cz/index.js" /* webpackChunkName: "component---src-pages-cz-index-js" */),
  "component---src-pages-cz-reference-js": () => import("./../../../src/pages/cz/reference.js" /* webpackChunkName: "component---src-pages-cz-reference-js" */),
  "component---src-pages-cz-rendering-js": () => import("./../../../src/pages/cz/rendering.js" /* webpackChunkName: "component---src-pages-cz-rendering-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-rendering-js": () => import("./../../../src/pages/rendering.js" /* webpackChunkName: "component---src-pages-rendering-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

